import React from 'react';

// Styles
import './MobileBanner.scss';

// Types
type Props = {
    title: string
    text: string
    icon: any
    altText: string
    color: string
    backgroundImageUrl?: string
}

const MobileBanner = (props: Props) => {
    const {
        title,
        text,
        icon,
        altText,
        color,
        backgroundImageUrl = 'none'
    } = props;

    return (
        <div className="r4z-mobile-section r4z-mobile-section__wrapper" style={{ backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none' }}>
            <div className="r4z-mobile-section__icon-wrapper">
                <img src={icon} alt={altText} className="r4z-mobile-section__icon" />
            </div>
            <div className="r4z-mobile-section__title-wrapper">
                <h2 className={`r4z-mobile-section__title r4z-mobile-section__title--${color}`}>
                    {title}
                </h2>
            </div>
            <div className="r4z-mobile-section__text-wrapper">
                <p className={`r4z-mobile-section__text r4z-mobile-section__text--${color}`}>
                    {text}
                </p>
            </div>
        </div>
    );
};

export default MobileBanner;
