import React, { useState } from 'react';

// Styles
import './RequestDemoModal.scss';

// UI Components
import Modal from '../../molecules/Modal';

// Types
type Props = {
    visible: boolean
    onClose: () => void
}

const RequestDemoModal = (props: Props) => {
    const {
        visible,
        onClose
    } = props;
    const [placeholder, changePlaceholder] = useState('Enter email here');
    const onSubmit = () => {
        changePlaceholder('Enter email here');
        onClose();
    };
    const onModalClose = () => {
        changePlaceholder('Enter email here');
        onClose();
    };
    return (
        <Modal
            title="Request Demo"
            {...{
                visible, onClose: onModalClose, okText: 'Submit', onOkClick: onSubmit
            }}
        >
            <section className="r4z-request-demo-modal__container">
                <p className="r4z-request-demo-modal__text">
                    Please enter your email below, and we will contact you later.
                </p>
                <form action="" className="r4z-request-demo-form__wrapper">
                    <input
                        type="text"
                        className="r4z-request-demo-form__input-field"
                        placeholder={placeholder}
                        onFocus={() => changePlaceholder('')}
                        onBlur={() => changePlaceholder('')}
                    />
                    <div className="r4z-request-demo-form__input-field-border" />
                </form>
            </section>
        </Modal>
    );
};

export default RequestDemoModal;
