import React, { useState } from 'react';
import Header from '../../templates/Header/Header';
import ContactUsModal from '../../organism/ContactUsModal';
import './PrivacyPolicy.scss';
import ContentSection from '../../organism/ContentSection';
import CloverBlack from '../../../assets/icon/icon_clover_black.png';
import SiteFooter from '../../templates/Footer';

const content = {
    component: 'content-section',
    mainTitleImg: null,
    mainTitle: 'Privacy Policy',
    icon: CloverBlack,
    iconAltText: 'petal leaf up',
    secondaryTitle: 'Connecting business for bigger purpose',
    textParagraphs: [
        'This is how we build Reaching 4 Zero - to be bridge between the ones who are in search of sustainability and ones who brings it to the World.',
        'We are here to prove sustainability is easy and we cannot imagine any future move made without confirming it is sustainable. '
            + 'Our team will guide you on your way finding sustainable decisions.'

    ],
    variant: 'white'
};

const PrivacyPolicy = () => {
    const [modalVisible, handleModal] = useState(false);
    const handleContactModalOpen = () => handleModal(true);
    const handleModalClose = () => handleModal(false);
    return (
        <div className="r4z-privacy-policy">
            <div className="r4z-privacy-policy__wrapper">
                <Header
                    topMenuVisible
                    headerColorTheme={{ menuColor: 'white', logoColor: 'white' }}
                    handleContactModalOpen={handleContactModalOpen}
                    types={['scroll']}
                />
                <ContactUsModal
                    visible={modalVisible}
                    onClose={handleModalClose}
                />
            </div>
            <ContentSection
                {...{
                    mainTitle: content.mainTitle,
                    mainTitleImg: content.mainTitleImg,
                    textParagraphs: content.textParagraphs,
                    variant: content.variant
                }}
            />
            <div className="r4z-about-page__footer-menu-wrapper">
                <SiteFooter {...{ handleContactModalOpen }} />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
