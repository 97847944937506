import React from 'react';

// Styles
import './MobileSectionTitle.scss';

// Types
type Props = {
    titleText: string
    titleColor: string

}

const MobileSectionTitle = (props: Props) => {
    const {
        titleText,
        titleColor = '#000000'
    } = props;
    return (
        <div className="r4z-mobile-section-title">
            <h2 className="r4z-mobile-section-title__text" style={{ color: titleColor }}>
                {titleText}
            </h2>
            <div className="r4z-mobile-section-title__vertical-line" style={{ borderColor: titleColor }} />
        </div>
    );
};

export default MobileSectionTitle;
