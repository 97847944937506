import React from 'react';

// Styles
import './Loader.scss';

// Types
type Props = {
    size: 'small' | 'normal' | 'large'
    color: 'white' | 'grey' | 'black' | 'white-transparent'
}

const Loader = (props: Props) => {
    const {
        size,
        color
    } = props;
    return (
        <div className={`r4z-loader r4z-loader--${size} r4z-loader--${color}`} />
    );
};

export default Loader;
