import React from 'react';

// Styles
import './Button.scss';

// UI Components
import Loader from '../Loader';

type ButtonProps = {
    text: string
    loading?: boolean
    loaderColor?: 'white' | 'grey' | 'black' | 'white-transparent'
    onClick?: (e: any) => void
    variant?: string
    fontSize?: string
    styleClass?: string
    disabled?: boolean
    htmlType?: 'button' | 'submit' | 'reset'
}
const Button = (props: ButtonProps) => {
    const {
        text,
        onClick,
        variant = '',
        fontSize = '1rem',
        styleClass = '',
        loading = false,
        loaderColor,
        disabled,
        htmlType = 'button'
    } = props;
    return (
        <button
            className={`r4z-button r4z-button--${variant} ${styleClass}`}
            onClick={onClick}
            style={{ fontSize }}
            type={htmlType}
            disabled={disabled}
        >
            <span className="r4z-button r4z-button__text">{text}</span>
            {loading && loaderColor && (
                <Loader size="small" color={loaderColor} />
            )}
        </button>
    );
};

export default Button;
