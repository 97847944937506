import React from 'react';

// Styles
import './Slide2ContentMobile.scss';

// Data
import { images, contentSections } from '../data';

const { pageTitle } = contentSections[1];

const Slide2ContentMobile = () => (
    <div className="r4z-2-slide-content-mobile">
        <div className="r4z-2-slide-content-mobile__page-title-wrapper">
            <h2 className="r4z-2-slide-content-mobile__page-title">{pageTitle}</h2>
            <div className="r4z-2-slide-content-mobile__page-title-after" />
        </div>
        <div className="r4z-2-slide-content-section-mobile">
            {contentSections.map(
                ({
                    title,
                    text,
                    icon,
                    color,
                    preTitle
                }, index) => (
                    <div
                        className={`r4z-2-slide-content-section-mobile__wrapper r4z-2-slide-content-section-mobile__wrapper--${color} r4z-2-slide-content-section-mobile__wrapper--${index + 1}`}
                        key={`Slide2ContentMobile${index}`}
                    >
                        <div className="r4z-2-slide-content-section-mobile__step-number">
                            {index + 1}
                        </div>
                        <div className="r4z-2-slide-content-section-mobile__title-wrapper">
                            <p>{preTitle}</p>
                            <h2 className="r4z-2-slide-content-section-mobile__title">{title}</h2>
                        </div>
                        <div className="r4z-2-slide-content-section-mobile__text-wrapper">
                            <p className="r4z-2-slide-content-section-mobile__text">
                                {text}
                            </p>
                        </div>
                        <div className="r4z-2-slide-content-section-mobile__icon-wrapper">
                            <img src={images[icon]} alt="Turtle Icon" />
                        </div>
                    </div>
                )
            )}
        </div>
    </div>
);

export default Slide2ContentMobile;
