import CloverBlack from '../../../assets/icon/icon_clover_black.png';
import LillyBlack from '../../../assets/icon/icon_lilly_black.png';

export const content = [
    {
        component: 'content-section',
        mainTitleImg: null,
        mainTitle: 'Mission',
        icon: CloverBlack,
        iconAltText: 'petal leaf up',
        secondaryTitle: 'Connecting business for bigger purpose',
        textParagraphs: [
            'This is how we build Reaching 4 Zero - to be bridge between the ones who are in search of sustainability and ones who brings it to the World.',
            'We are here to prove sustainability is easy and we cannot imagine any future move made without confirming it is sustainable. '
            + 'Our team will guide you on your way finding sustainable decisions.'

        ],
        variant: 'white'
    },
    {
        component: 'content-section',
        mainTitle: 'Our Mantra',
        mainTitleImg: null,
        icon: LillyBlack,
        iconAltText: 'petal leaf down',
        secondaryTitle: 'What goes around comes around ',
        textParagraphs: [
            'We trust in our bigger purpose. Together, joining our forces we will help our Planet to succeed in stopping damage and recover.',
            'Progressive thoughts and circularity of our good decisions today will come as a result in near future. Making a good, sustainable decision today will impact better tomorrow. '

        ],
        variant: 'grey'
    },
    {
        component: 'content-section-hero-banner',
        title: 'Join reaching 4 Zero community to know more',
        text: 'We promise you easy and clever solutions to improve your business impact on sustainability and guide you on this journey.',
        image: '/images/forest_and_path.png'
    },
    {
        component: 'content-section-contact'
    },
    {
        component: 'content-section-hero-banner',
        title: 'Read more in our articles',
        text: 'We constantly share tips and tricks for quick sustainable fixes to help improving your day-to-day tasks.',
        image: '/images/red_flower.png',
        buttonText: 'Articles',
        buttonLinkOpenNewTab: true,
        buttonLink: 'https://www.linkedin.com/company/reaching-for-zero/posts'

    }
];
