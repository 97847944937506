import React from 'react';

import './Section.scss';

type Props = {
    col?: 1 | 2 | 3 | 4 | 5 | 6
    children?: React.ReactNode
}

const Section: React.FC<Props> = (props: Props) => {
    const { col = 1, children } = props;
    return (
        <div className={`r4z-page-slide__section r4z-page-slide__col--${col}`}>
            {children}
        </div>
    );
};

Section.displayName = 'Section';

export default Section;
