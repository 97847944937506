import React from 'react';

// Icons
import IconDownWhite from '../../../assets/icon/arrow_down_white.svg';
import IconDownBlack from '../../../assets/icon/arrow_down_black.svg';
import IconDownLightBlack from '../../../assets/icon/arrow_down_light_black.svg';

// Styles
import './ArrowIcon.scss';

// Types
export interface ArrowIconProps {
    type: 'down' | 'up'
    color: 'black' | 'white' | 'lightBlack'
    animation?: boolean
    text?: string
    arrowSize?: string
    fontSize?: string
}

const ArrowIcon = (props: ArrowIconProps) => {
    const {
        type,
        color,
        animation,
        text,
        arrowSize = '45px',
        fontSize = '1em'
    } = props;

    const arrowImages = {
        up: '',
        down: {
            white: IconDownWhite,
            black: IconDownBlack,
            lightBlack: IconDownLightBlack
        }
    };
    return (
        <>
            {text
            && (
                <p
                    className={`r4z-scroll-down-button__text r4z-scroll-down-button__text--${color}`}
                    style={{ fontSize }}
                >
                    {text}
                </p>
            )}
            <img
                className={`r4z-scroll-down-button__image ${animation ? 'r4z-scroll-down-button__image--animation' : ''}`}
                style={{ maxWidth: arrowSize }}
                src={arrowImages[type][color]}
                alt="Navigation button"
            />
        </>
    );
};

export default ArrowIcon;
