import React, { useEffect } from 'react';

// Router
import {
    createBrowserRouter,
    RouterProvider,
    useLocation
} from 'react-router-dom';

// Styles
import './components/molecules/Message/message.scss';
import './assets/styles/global.scss';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { changeIsMobile } from './redux/actions';

// Pages
import Home from './components/pages/Home';
import About from './components/pages/About';
import Blog from './components/pages/Blog';
import Login from './components/pages/Login';
import CalculatorStart from './components/pages/CalculatorStart';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfUse from './components/pages/TermsOfUse';

const updateDimensions = (): void => {
    const currIsMobile = window.innerWidth < 720;
    const prevIsMobile = store.getState().isMobile;
    if (prevIsMobile !== currIsMobile) {
        store.dispatch(changeIsMobile(currIsMobile));
    }
};

const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/about-us', element: <About /> },
    { path: '/articles', element: <Blog /> },
    { path: '/login', element: <Login /> },
    { path: '/privacy-policy', element: <CalculatorStart /> },
    { path: '/terms-of-use', element: <PrivacyPolicy /> },
    { path: '/calculator', element: <TermsOfUse /> },
    { path: '*', element: <NoMatch /> }

]);

function App() {
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        updateDimensions();
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className="app">
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </div>
    );
}

function NoMatch() {
    const location = useLocation();

    return (
        <div>
            <h3>
                No match for
                {' '}
                <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

export default App;
