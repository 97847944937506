import React from 'react';

// Styles
import './Slide2Content.scss';

// Components
import Button from '../../../../../atoms/Button';

// Data
import { images, contentSections } from '../data';

// Components
import ArrowIcon from '../../../../../atoms/ArrowIcon';

// Types
type Props = {
    isActive: boolean
    onScrollClick?: () => void
}

const Slide2Content = (props: Props) => {
    const {
        isActive,
        onScrollClick
    } = props;

    return (
        <div className={`r4z-2-slide-content__wrapper ${isActive ? 'active' : ''}`}>
            {contentSections.map(
                ({
                    title, text, icon, color, pageTitle, preTitle, button
                }, index) => (
                    <section
                        key={`${index}slide-2`}
                        className={`r4z-2-slide-content__section r4z-2-slide-content__section--${index + 1} r4z-2-slide-content__section--${color}`}
                    >
                        {pageTitle && (
                            <div className="r4z-2-slide-content__page-title-wrapper">
                                <h2 className="r4z-2-slide-content__page-title">{pageTitle}</h2>
                            </div>
                        )}
                        <div className="r4z-2-slide-content-section__wrapper">
                            <div className="r4z-2-slide-content-section__step-number">
                                {index + 1}
                            </div>
                            <div className="r4z-2-slide-content-section__title-wrapper">
                                <p>{preTitle}</p>
                                <h2 className="r4z-2-slide-content-section__title">{title}</h2>
                            </div>
                            <div className="r4z-2-slide-content-section__text-wrapper">
                                <p className="r4z-2-slide-content-section__text">
                                    {text}
                                </p>
                            </div>
                        </div>
                        <div className="r4z-2-slide-content-section__icon">
                            <img src={images[icon]} alt="Turtle Icon" />
                        </div>
                        {
                            button && (
                                <Button
                                    text="Get Started"
                                    styleClass="r4z-2-slide-content-section__button"
                                    onClick={() => {
                                    }}
                                    fontSize="1.6em"
                                />
                            )
                        }
                    </section>
                )
            )}
            {onScrollClick && (
                <div
                    className="r4z-slide-content__scroll-down-button-wrapper"
                    onClick={onScrollClick}
                    onKeyDown={onScrollClick}
                    role="button"
                    tabIndex={0}
                >
                    <ArrowIcon
                        type="down"
                        color="white"
                        text="Scroll for More"
                        animation
                    />
                </div>
            )}
        </div>
    );
};

export default Slide2Content;
