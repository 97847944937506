export const defaultFields = [
    {
        inputId: 'username',
        name: 'username',
        type: 'text',
        valid: true,
        placeholder: 'User name',
        icon: 'user',
        height: '50px',
        validators: [{
            name: 'notEmpty',
            errorMessage: `User name can't be empty.`
        }],
        value: ''
    },
    {
        inputId: 'password',
        name: 'password',
        type: 'password',
        placeholder: 'Password',
        icon: 'lock',
        valid: true,
        height: '50px',
        validators: [{
            name: 'notEmpty',
            errorMessage: `Password can't be empty.`
        }],
        value: ''
    }
];
