import React, { useState, useEffect } from 'react';

// Styles
import './InputStyles.scss';

// Assets
import UserIcon from './assets/icons/user_icon.svg';
import LockIcon from './assets/icons/lock_icon.svg';
import AttentionIcon from './assets/icons/attention.svg';

type Props = {
    inputId: string
    name: string
    label?: string
    width?: string | number
    height?: string | number
    icon?: string
    type?: string
    placeholder?: string
    valid?: boolean
    disabled?: boolean
    required?: boolean
    error?: string
    onChange: (value: string) => void
    onFocus: (e: any) => void
}

const icons = {
    user: UserIcon,
    lock: LockIcon,
    attention: AttentionIcon
};

const getLabelStyleClass = (isValid: boolean, required: boolean): string => `r4z-input__label ${isValid ? '' : 'r4z-input__label--invalid'} ${required ? 'r4z-input__label--required' : ''}`;

const Input = (props: Props) => {
    const {
        inputId,
        name,
        label,
        width,
        height,
        icon,
        type = 'text',
        placeholder = '',
        valid = true,
        disabled = false,
        required = false,
        error,
        onChange,
        onFocus
    } = props;
    const [isValid, handleIsValid] = useState(valid);
    const [inputValue, handleInputChange] = useState('');
    useEffect(() => {
        handleIsValid(valid);
        if (!valid) {
            handleInputChange('');
        }
    }, [valid]);
    return (
        <>
            {label && (
                <label htmlFor={inputId} className={getLabelStyleClass(isValid, required)}>
                    {label}
                </label>
            )}
            <div className="r4z-input__wrapper">
                {
                    icon && (
                        <div className="r4z-input__icon-wrapper">
                            <img src={isValid ? icons[icon] : icons.attention} alt={icon} className="r4z-input__icon-image" />
                        </div>
                    )
                }
                <input
                    className={`r4z-input ${isValid ? '' : 'r4z-input--invalid'} ${icon ? 'r4z-input--withIcon' : ''}`}
                    style={{ height, width }}
                    id={inputId}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={inputValue}
                    onChange={(e) => {
                        const { value } = e.target as HTMLInputElement;
                        handleInputChange(value);
                        onChange(value);
                        handleIsValid(true);
                    }}
                    onFocus={onFocus}
                />
            </div>
            {error && !isValid && (
                <div className="r4z-input__error-wrapper">
                    <p>{error}</p>
                </div>
            )}
        </>
    );
};

export default Input;
