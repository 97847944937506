import React, { useState } from 'react';

// Styles
import './Header.scss';

// HOC
import withMobile from '../../../lib/hoc/withMobile';

// UI Components
import TopMenu from '../../organism/TopMenu';
import SideMenu from '../../organism/SideMenu';
import RequestDemoModal from '../../organism/RequestDemoModal';

// Assets
import LogoWhite from '../../../assets/img/Logo100x100_white.svg';
import LogoBlack from '../../../assets/img/Logo100x100_black.svg';

// Data
import { menuItems, MenuItem } from './data';

// Types
type ReduxProps = {
    isMobile: boolean;
}

type HeaderProps = {
    topMenuVisible?: boolean
    headerColorTheme?: {
        menuColor: string
        logoColor: string
    }
    headerBackgroundColor?: string
    types?: string[]
    handleContactModalOpen: () => void
}

type Props = HeaderProps & ReduxProps

const handleNavigate = (url: string | null, newTab: boolean): void => {
    newTab ? window.open(url, '_blank') : window.location.href = url;
};

const requestDemoSideMenuLink: MenuItem = {
    title: 'Request Demo', link: null, newTab: false, permissions: ['public'], action: 'open-request-demo-modal'
};

const privacyPolicyLink: MenuItem = {
    title: 'Privacy Policy', link: '/privacy-policy', newTab: false, permissions: ['public'], action: 'navigate'
};

const Header = (props: Props) => {
    const {
        topMenuVisible = true,
        headerColorTheme = { menuColor: 'white', logoColor: 'white' },
        headerBackgroundColor = 'transparent',
        types = ['sticky'],
        handleContactModalOpen,
        isMobile
    } = props;
    const [showSideMenu, toggleSideMenu] = useState(false);
    const [showRequestDemoModal, toggleRequestDemoModal] = useState(false);
    const closeRequestDemoModal = () => toggleRequestDemoModal(false);
    const openRequestDemoModal = () => toggleRequestDemoModal(true);
    const topMenuLinks = menuItems.map((link) => {
        if (link.action === 'open-contact-modal') {
            link.onClick = handleContactModalOpen;
        } else if (link.action === 'open-request-demo-modal') {
            link.onClick = openRequestDemoModal;
        }
        return link;
    });
    const sideMenuLinks = topMenuLinks.concat(privacyPolicyLink, { onClick: handleContactModalOpen, ...requestDemoSideMenuLink });
    return (
        <header className={`r4z-header ${types.map((i) => `r4z-header--${i}`).join(' ')}`} style={{ backgroundColor: headerBackgroundColor }}>
            <div className="r4z-header__inner-wrapper">
                <a href="/" className="r4z-header__logo-wrapper">
                    {headerColorTheme.logoColor === 'black' ? (
                        <img className="r4z-header__logo-image" src={LogoBlack} alt="Reaching for Zero" />
                    ) : (
                        <img className="r4z-header__logo-image" src={LogoWhite} alt="Reaching for Zero" />
                    )}
                    <h2 className={`r4z-header__logo-text r4z-header__logo-text--${headerColorTheme.logoColor}`}>
                        Reaching 4 Zero
                    </h2>
                </a>
                <div className="r4z-header__menu-wrapper">
                    <TopMenu
                        showMenuIcon={!topMenuVisible || isMobile}
                        variant={headerColorTheme.menuColor}
                        onButtonClick={openRequestDemoModal}
                        {...{ toggleSideMenu, menuItems, handleNavigate }}
                    />
                    <SideMenu
                        {...{
                            showSideMenu, toggleSideMenu, menuItems: sideMenuLinks, handleNavigate
                        }}
                    />
                </div>
            </div>
            <RequestDemoModal
                visible={showRequestDemoModal}
                onClose={closeRequestDemoModal}
            />
        </header>
    );
};

export default withMobile<HeaderProps>({})(Header);
