import React from 'react';

import Section from '../Section';

import './Wrapper.scss';

type Props = {
    children?: React.ReactNode
}

const childNode = (parentProps) => (child) => {
    const { props } = child;
    if (child.type.displayName === Section.displayName && React.isValidElement(child)) {
        return React.cloneElement(child, { ...parentProps, ...props });
    }
    throw new Error('Invalid Element provided. PageSlide only accepts PageSlide.Section Component');
};

const Wrapper: React.FC<Props> = (props: Props) => {
    const { children } = props;
    return (
        <div className="r4z-page-slide__wrapper">
            {React.Children.map(children, childNode({ col: React.Children.count(children) }))}
        </div>
    );
};

export default Wrapper;
