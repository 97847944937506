import React, { useEffect, useState } from 'react';

// Styles
import './SideMenu.scss';

// UI Components
import SideSlider from '../../molecules/SideSlider';

// Assets
import LogoBlack from '../../../assets/img/Logo100x100_black.svg';

// Types
import { MenuItem } from '../../templates/Header/data';

type Props = {
    showSideMenu: boolean
    toggleSideMenu: (state: boolean) => void
    menuItems: MenuItem[]
    handleNavigate: (url: string | null, newTab: boolean) => void
}

const SideMenu = (props: Props) => {
    const {
        menuItems,
        showSideMenu,
        toggleSideMenu,
        handleNavigate
    } = props;
    const [shouldShow, toggleShouldShow] = useState(false);
    useEffect(() => {
        toggleShouldShow(showSideMenu);
    }, [showSideMenu]);

    return (
        <SideSlider
            isOpen={shouldShow}
            onCloseClick={() => toggleSideMenu(false)}
        >
            <div className="r4z-side-menu__logo-wrapper">
                <img className="r4z-header__logo-image" src={LogoBlack} alt="Reaching for Zero" />
                <h2 className="r4z-header__logo-text r4z-header__logo-text--black">
                    Reaching 4 Zero
                </h2>
            </div>
            <nav className="r4z-side-menu">
                {menuItems.map(({
                    title,
                    link,
                    newTab,
                    action,
                    onClick
                }) => (
                    <div
                        className="r4z-side-menu__link-wrapper"
                        key={title}
                    >
                        <a
                            className={`r4z-side-menu__link ${window.location.pathname === link ? 'r4z-side-menu__link--active' : ''}`}
                            onClick={action === 'navigate' ? () => handleNavigate(link, newTab) : () => {
                                onClick();
                                toggleSideMenu(false);
                            }}
                            onKeyDown={action === 'navigate' ? () => handleNavigate(link, newTab) : () => {
                                onClick();
                                toggleSideMenu(false);
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {title}
                        </a>
                    </div>
                ))}
            </nav>
        </SideSlider>
    );
};

export default SideMenu;
