import React from 'react';

// Styles
import './ContentSectionHeroBanner.scss';

// UI Components
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';

// Types
type Props = {
    title: string
    text: string
    image: any
    imageAuthor?: string
    imageAuthorUrl?: string
    buttonLink?: string
    buttonText?: string
    buttonAction?: () => void
    buttonLinkOpenNewTab?: boolean
}

const navigate = (url: string, newTab: boolean = false): void => {
    newTab ? window.open(url, '_blank') : window.location.href = url;
};

const ContentSectionHeroBanner = (props: Props) => {
    const {
        title,
        text,
        image,
        imageAuthor = '',
        imageAuthorUrl = '',
        buttonAction,
        buttonLink,
        buttonText,
        buttonLinkOpenNewTab = false
    } = props;

    return (
        <div className={`r4z-content-section-hero-banner ${imageAuthor ? 'r4z-content-section-hero-banner--withAuthor' : ''}`}>
            <div className="r4z-content-section-hero-banner__wrapper" style={{ backgroundImage: `url(${image})` }}>
                <h2 className="r4z-content-section-hero-banner__title">
                    {title}
                </h2>
                <div className="r4z-content-section-hero-banner__border-line" />
                <h4 className="r4z-content-section-hero-banner__text">
                    {text}
                </h4>
                {buttonLink && (
                    <Button
                        text={buttonText}
                        variant="white-transparent"
                        fontSize="13.33px"
                        styleClass="r4z-content-section-hero-banner__button"
                        onClick={() => (buttonLink ? navigate(buttonLink, buttonLinkOpenNewTab) : buttonAction())}
                    />
                )}
            </div>
            {imageAuthor && (
                <div className="r4z-content-section-hero-banner__author-wrapper">
                    <Link href={imageAuthorUrl} openTab>
                        <p className="r4z-content-section-hero-banner__author">{imageAuthor}</p>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ContentSectionHeroBanner;
