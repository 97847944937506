import React, { useState } from 'react';

import './LoginForm.scss';

// UI Components
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import Message from '../../molecules/Message';

// Utils
import { validateField } from './utils';

// Data
import { defaultFields } from './data';

const handleSubmit = (e, formValues, formFields, handleFormFields, handleLoading) => {
    e.preventDefault();
    let formHasErrors = false;
    const updatedFields = formFields.map((field) => {
        const {
            name,
            validators
        } = field;
        const error = validateField(validators, formValues[name]);
        if (error) {
            formHasErrors = true;
            return {
                ...field,
                valid: false,
                error
            };
        }
        return { ...field };
    });
    if (formHasErrors) {
        handleFormFields(updatedFields);
    } else {
        handleLoading(true);
        setTimeout(() => {
            handleLoading(false);
            Message.error('Please enter a correct username and password.');
        }, 2000);
    }
};

const handleFocus = (e, formFields, handleFormFields) => {
    const fieldName = e.target.name;
    formFields.find(({ name }, index) => {
        if (name === fieldName) {
            const fields = formFields.slice();
            fields[index] = {
                ...fields[index],
                valid: true
            };
            handleFormFields(fields);
            return true;
        }
        return false;
    });
};

const updateFormValues = (field, value, formValues, handleValuesChange) => {
    const values = {
        ...formValues,
        [field]: value
    };
    handleValuesChange(values);
};

const initFormValues = defaultFields.reduce((x, y) => ({
    ...x,
    [y.name]: null
}), {});

const LoginForm = () => {
    const [loading, handleLoading] = useState(false);
    const [formValues, handleValuesChange] = useState({ ...initFormValues });
    const [formFields, handleFormFields] = useState(defaultFields.map((i) => ({ ...i })));

    return (
        <div className="r4z-login-form__wrapper">
            <form
                onSubmit={(e) => handleSubmit(e, formValues, formFields, handleFormFields, handleLoading)}
            >
                {formFields.map(({
                    inputId,
                    name,
                    type,
                    valid,
                    placeholder,
                    icon,
                    height,
                    validators
                }) => (
                    <div className="r4z-login-form__field-wrapper" key={`login-form${name}`}>
                        <Input
                            {...{
                                inputId,
                                name,
                                type,
                                valid,
                                placeholder,
                                icon,
                                height,
                                validators
                            }}
                            onChange={(value) => updateFormValues(
                                name,
                                value,
                                formValues,
                                handleValuesChange
                            )}
                            onFocus={(e) => handleFocus(e, formFields, handleFormFields)}
                        />
                    </div>
                ))}
                <div className="r4z-login-form__button_wrapper">
                    <Button
                        text="Sign in"
                        loading={loading}
                        loaderColor="white"
                        fontSize="1.4em"
                        styleClass="r4z-login-form__button"
                        onClick={(e) => handleSubmit(e, formValues, formFields, handleFormFields, handleLoading)}
                    />
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
