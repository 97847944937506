import React from 'react';
import { Link } from 'react-router-dom';

// UI Components
import Button from '../../atoms/Button';

// Images
import LogoBlack from '../../../assets/img/Logo100x100_black.svg';

// Styles
import './CalculatorStart.scss';

// Auth0
import { buildAuth0LoginUrl } from '../../../lib/utils/auth0Handlers';

const handleStartClick = async () => {
    window.location.href = buildAuth0LoginUrl();
};

const CalculatorStart = () => (
    <div className="r4z-calc-start-page__wrapper">
        <main className="r4z-calc-start-page__body">
            <h1 className="r4z-calc-start-page__title">
                <span>WELCOME TO</span>
                <br />
                <span className="r4z-calc-start-page__title--color">SUSTAINABLE IMPACT</span>
                <br />
                CALCULATOR
            </h1>
            <p className="r4z-calc-start-page__title-text">
                Evaluate your sustainable impact
                {' '}
                <span className="r4z-calc-start-page__title-text r4z-calc-start-page__title-text--active">
                    with a few smart steps!
                </span>
            </p>
            <p className="r4z-calc-start-page__title-text">
                Get started by clicking link below
            </p>
            <Button
                {...{
                    styleClass: 'r4z-calc-start-page__start-button',
                    loaderColor: 'white',
                    fontSize: '1.5rem',
                    text: 'Start',
                    onClick: handleStartClick
                }}
            />
            <p className="r4z-calc-start-page__consent-text">
                By clicking &quot;Start&quot; you agree to Reaching 4 zero’s
                {' '}
                <a href="/privacy-policy" target="_blank">privacy policy</a>
                ,
                {' '}
                <br />
                and consent to receive Reaching 4 zero marketing communications.
            </p>
        </main>
        <div className="r4z-calc-start-page__footer-wrapper">
            <footer className="r4z-calc-footer">
                <Link
                    to="/"
                    className="r4z-calc-footer__text"
                >
                    <span className="r4z-calc-footer__text r4z-calc-footer__text--with-color">
                        Powered by &nbsp;
                    </span>
                    Reaching 4 Zero
                    <span className="r4z-calc-footer__logo">
                        <img src={LogoBlack} alt="R4Z Logo" />
                    </span>
                </Link>
            </footer>
        </div>
    </div>
);

export default CalculatorStart;
