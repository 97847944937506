import React from 'react';

// Styles
import './VisionSlide.scss';

// UI Components
import PageSlide from '../../../../../templates/PageSlide';

// Images
import heroImage from '../../../../../../assets/img/r4z-vision-target-banner.png';
import dualLeafIcon from '../../../../../../assets/icon/dual-leaf-tree-white.png';
import flowerIcon from '../../../../../../assets/icon/flower-icon-black.png';

// Data
import { contentSections } from '../data';

type Props = {
    isActive: boolean
}

const VisionSlide = (props: Props) => {
    const {
        isActive
    } = props;
    return (
        (
            <div className={`r4z-3-slide-content ${isActive ? 'active' : ''}`}>
                <PageSlide>
                    <PageSlide.Section>
                        <div className="r4z-3-slide-content-left__wrapper">
                            <div className="r4z-3-slide-content-left__title-wrapper">
                                <h2 className="r4z-3-slide-content-left__title">
                                    Vision
                                </h2>
                            </div>
                            <div className="r4z-3-slide-content-left__image-wrapper">
                                <img src={heroImage} alt="" className="r4z-3-slide-content-left__image" />
                            </div>
                            <div className="r4z-3-slide-content-left__icon-wrapper">
                                <img src={flowerIcon} alt="flower-icon" className="r4z-3-slide-content-left__icon" />
                            </div>
                        </div>
                    </PageSlide.Section>
                    <PageSlide.Section>
                        <div className="r4z-3-slide-content-right__wrapper">
                            {contentSections.map(({ sectionTitle, textTitle, text }, index) => (
                                <section className="r4z-3-slide-content-right__section-wrapper" key={`Slide3ContentMobile${index}`}>
                                    <div className="r4z-3-slide-content-right__section-title-wrapper">
                                        <h2 className="r4z-3-slide-content-right__section-title">
                                            {sectionTitle}
                                        </h2>
                                    </div>
                                    <div className="r4z-3-slide-content-right__section-text-wrapper">
                                        <h2 className="r4z-3-slide-content-right__section-text-title">
                                            {textTitle}
                                        </h2>
                                        <p className="r4z-3-slide-content-right__section-text">
                                            {text}
                                        </p>
                                    </div>
                                </section>
                            ))}
                            <div className="r4z-3-slide-content-right__icon-wrapper">
                                <img src={dualLeafIcon} alt="leaf-icon" className="r4z-3-slide-content-right__icon" />
                            </div>
                        </div>

                    </PageSlide.Section>
                </PageSlide>
            </div>
        )
    );
};

export default VisionSlide;
