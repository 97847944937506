export const contentSections = [
    {
        sectionTitle: 'why',
        textTitle: 'value',
        text: 'We empower businesses to succeed in saving company\'s resources, efforts and expenses as well as reaching sustainability goals.'
    },
    {
        sectionTitle: 'what',
        textTitle: 'match',
        text: 'By showing sustain impact ranking, we direct businesses to partners environment with possibility to make actions immediately.'
    },
    {
        sectionTitle: 'how',
        textTitle: 'impact',
        text: 'Reaching for Zero is B2B platform which provides impact calculator, connects to sustainable partners, helps to report and become compliant with government regulations.'
    }
];
