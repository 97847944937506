export type MenuItem = {
    title: string
    link: string
    newTab: boolean
    permissions: string[]
    onClick?: () => any
    action: string
}

export const menuItems: MenuItem[] = [
    {
        title: 'Home',
        link: '/',
        newTab: false,
        action: 'navigate',
        permissions: ['public']
    },
    {
        title: 'About Us',
        link: '/about-us',
        newTab: false,
        action: 'navigate',
        permissions: ['public']
    },
    {
        title: 'Articles',
        link: 'https://www.linkedin.com/company/reaching-for-zero/posts',
        newTab: true,
        action: 'navigate',
        permissions: ['public']
    },
    {
        title: 'Contact Us',
        link: null,
        newTab: false,
        action: 'open-contact-modal',
        permissions: ['public']
    }
];
