import HazelnutLeafWhite from '../../../../../assets/icon/hazelnut_leaf_white.svg';
import OakLeafBlack from '../../../../../assets/icon/oak_leaf_black.svg';
import ChestnutLeafWhite from '../../../../../assets/icon/chestnut_leaf_white.svg';
import PetalWhite from '../../../../../assets/icon/petal_white.svg';
import PetalBlackReverse2 from '../../../../../assets/icon/petal_black_reverse.png';

export const contentSections = [
    {
        title: 'gets you qualified',
        text: 'Wide selection of best-in-a-market certified companies will consult you, lead and guide you towards zero.',
        icon: HazelnutLeafWhite,
        mobileIcon: PetalWhite,
        color: 'white',
        altText: 'leaf',
        backgroundImageUrl: '/images/forest_mobile.png'
    },
    {
        pageTitle: 'our partners',
        title: 'gets you certified',
        text: 'The must-have world valued certificates, licenses and labels for recognizing your company between sustainable and responsible businesses of the World.',
        icon: OakLeafBlack,
        mobileIcon: PetalBlackReverse2,
        color: 'black',
        altText: 'leaf'
    },
    {
        title: 'Match',
        text: 'Knowing your sustain impact, we direct you to partners environment to act immediately.',
        icon: ChestnutLeafWhite,
        mobileIcon: PetalWhite,
        color: 'white',
        altText: 'leaf',
        backgroundImageUrl: '/images/forest_fall_mobile.png'
    }
];

export const images = {
    hazelnutLeaf: HazelnutLeafWhite,
    oakLeaf: OakLeafBlack,
    chestnutLeaf: ChestnutLeafWhite
};
