import React from 'react';

// Styles
import './Slide1Content1.scss';

// Components
// import VideoPlayer from "../../../components/organism/VideoPlayer";
import ArrowIcon from '../../../../../atoms/ArrowIcon';

type Props = {
    onScrollClick?: () => void
    isActive: boolean
}

const Slide1Content = (props: Props) => {
    const {
        onScrollClick,
        isActive
    } = props;
    return (
        <div className={` ${isActive ? 'active' : ''} r4z-1-slide-content`}>
            {/* <VideoPlayer */}
            {/*    videoUrl={'/media/hero-video-animals.mp4'} */}
            {/* /> */}
            <div className="r4z-1-slide-content__wrapper">
                <h2 className="r4z-1-slide-content__title">
                    Innovations for sustainability
                    {' '}
                    <br />
                    {' '}
                    seekers and providers
                </h2>
                <p className="r4z-1-slide-content__text">
                    Designed to apply sustainability to all your business layers
                    {' '}
                    based on smart solutions
                </p>
            </div>
            {onScrollClick && (
                <div
                    className="r4z-slide-content__scroll-down-button-wrapper"
                    onClick={onScrollClick}
                    onKeyDown={onScrollClick}
                    role="button"
                    tabIndex={0}
                >
                    <ArrowIcon
                        type="down"
                        color="white"
                        text="Scroll for More"
                        animation
                    />
                </div>
            )}
        </div>
    );
};

export default Slide1Content;
