import React, { useState } from 'react';

// Styles
import './Modal.scss';

// Types
type Props = {
    visible: boolean
    onClose: () => void
    title?: string
    header?: JSX.Element
    footer?: JSX.Element
    okText?: string
    onOkClick?: () => void
    width?: string
    maxWidth?: string
    children?: React.ReactNode
}

const Modal = (props: Props) => {
    const [animation, handleAnimation] = useState('open');
    const {
        visible,
        header,
        footer,
        title,
        onClose,
        onOkClick,
        okText,
        width = '100%',
        maxWidth = '600px',
        children
    } = props;

    const handleClose = () => {
        handleAnimation('close');
        const timeout = setTimeout(() => {
            onClose();
            handleAnimation('open');
            clearTimeout(timeout);
        }, 300);
    };

    return visible ? (
        <div className="r4z-modal__overlay">
            <div className={`r4z-modal__wrapper r4z-modal--${animation}`} style={{ width, maxWidth }}>
                <div className="r4z-modal__container">
                    <div className="r4z-modal__header">
                        <div className="r4z-modal-header__title-wrapper">
                            {header || (
                                <h2 className="r4z-modal-header__title">
                                    {title}
                                </h2>
                            )}
                        </div>
                        <button
                            type="button"
                            className="r4z-modal-header__x-button"
                            data-target="parts-modal"
                            aria-label="close"
                            onClick={onClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="r4z-modal__body">
                        {children}
                    </div>
                    <div className="r4z-modal__footer">
                        {footer ? <footer /> : (
                            <>
                                {okText && (
                                    <button
                                        type="button"
                                        className="r4z-modal-footer__button r4z-modal-footer__button--ok"
                                        data-target="parts-modal"
                                        aria-label="ok button"
                                        onClick={onOkClick}
                                    >
                                        <span aria-hidden="true">{okText}</span>
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="r4z-modal-footer__button r4z-modal-footer__button--close"
                                    data-target="parts-modal"
                                    aria-label="close"
                                    onClick={handleClose}
                                >
                                    <span aria-hidden="true">Close</span>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Modal;
