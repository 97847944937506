import React from 'react';

// Styles
import './ContentSection.scss';

interface Data {
    mainTitle?: string
    mainTitleImg?: string
    icon?: any
    iconAltText?: string
    secondaryTitle?: string
    textParagraphs: string[]
    variant?: string
}

type Props = Data;

const ContentSection = (props: Props) => {
    const {
        mainTitle,
        mainTitleImg,
        icon,
        iconAltText,
        secondaryTitle,
        textParagraphs,
        variant = 'white'
    } = props;

    return (
        <div className={`r4z-content-section-component r4z-content-section-component--${variant}`}>
            <div className="r4z-content-section-component__main-title-wrapper">
                {mainTitle && (
                    <h2 className="r4z-content-section-component__main-title">{mainTitle}</h2>
                )}
                {mainTitleImg && (
                    <img src={mainTitleImg} alt="" className="r4z-content-section-component__main-title-image" />
                )}
            </div>
            <div className="r4z-content-section-component__page-title-after" />
            {icon && (
                <div className="r4z-content-section-component__icon-wrapper">
                    <img src={icon} alt={iconAltText} className="r4z-content-section-component__icon" />
                </div>
            )}
            {secondaryTitle && (
                <div className="r4z-content-section-component__secondary-title-wrapper">
                    <h2 className="r4z-content-section-component__secondary-title">
                        {secondaryTitle}
                    </h2>
                </div>
            )}
            <div className="r4z-content-section-component__paragraph-wrapper">
                {textParagraphs.map((text, index) => (
                    <p key={`r4z-content-section-component-p${index}`} className="r4z-content-section-component__paragraph">
                        {text}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ContentSection;
