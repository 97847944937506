import React from 'react';

// Styles
import './Slide4ContentMobile.scss';

// UI Components
import MobileSectionTitle from '../../../../../atoms/MobileSectionTitle';
import MobileBanner from '../../../../../molecules/MobileBanner';

// Data
import { contentSections } from '../data';

const { pageTitle } = contentSections[1];

const Slide4ContentMobile = () => (
    <div className="r4z-4-slide-content">
        <MobileSectionTitle titleText={pageTitle} titleColor="#4e6d65" />
        {contentSections.map(({
            title,
            text,
            mobileIcon,
            altText,
            color,
            backgroundImageUrl
        }, index) => (
            <MobileBanner
                key={`slide4ContentMobile${index}`}
                {...{
                    title,
                    text,
                    icon: mobileIcon,
                    altText,
                    color,
                    backgroundImageUrl
                }}
            />
        ))}
    </div>
);

export default Slide4ContentMobile;
