// Lib
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, CALCULATOR_REDIRECT_URL } from '../constants';

// Utils
import { buildQueryParams } from './buildQueryParams';

export const buildAuth0LoginUrl = () => {
    const params = buildQueryParams({
        response_type: 'code',
        client_id: AUTH0_CLIENT_ID,
        redirect_uri: CALCULATOR_REDIRECT_URL,
        scope: 'openid%20profile%20email',
        state: ''
    });
    return `https://${AUTH0_DOMAIN}/authorize${params}`;
};
