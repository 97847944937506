import React, { useState } from 'react';

// Styles
import './Slide6Content.scss';

// HOC
import withMobile from '../../../../../../lib/hoc/withMobile';

// UI Components
import Button from '../../../../../atoms/Button';

// Types
type ReduxProps = {
    isMobile: boolean
}

type ComponentProps = {
    isActive: boolean
}

type Props = ReduxProps & ComponentProps

const handleButtonClick = (event) => {
    event.preventDefault();
};

const Slide6Content = (props: Props) => {
    const [placeholder, changePlaceholder] = useState('Email Address');
    const {
        isActive,
        isMobile
    } = props;
    return (
        <div className="r4z-6-slide-content">
            <div
                className={`r4z-6-slide-content__wrapper ${isActive ? 'r4z-6-slide-content__wrapper--animation' : ''}`}
            >
                <div className="r4z-6-slide-content__text-wrapper">
                    <p className="r4z-6-slide-content__text">
                        For more information
                    </p>
                    <h2 className="r4z-6-slide-content__title">
                        Request demo
                    </h2>
                </div>
                <form action="" className="r4z-6-slide-content-form__wrapper">
                    <input
                        type="text"
                        className="r4z-6-slide-content-form__input-field"
                        placeholder={placeholder}
                        onFocus={() => changePlaceholder('')}
                        onBlur={() => changePlaceholder('')}
                    />
                    <div className="r4z-6-slide-content-form__input-field-border" />
                    <div className="r4z-6-slide-content-form__button-wrapper">
                        <Button
                            text="Submit"
                            onClick={handleButtonClick}
                            styleClass="r4z-6-slide-content-form__submit-button"
                            fontSize={isMobile ? '1.1em' : '1.5em'}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withMobile<ComponentProps>({})(Slide6Content);
