export const menuItems = [
    // {
    //     text: 'Privacy Policy',
    //     url: '/privacy-policy',
    //     type: 'link',
    //     variant: 'right-pipe'
    // },
    // {
    //     text: 'Terms of Use',
    //     url: '/terms-of-use',
    //     type: 'link',
    //     variant: 'right-pipe'
    // },
    // {
    //     text: 'Contact Us',
    //     type: 'contactModal'
    // }
];
