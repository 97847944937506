import React from 'react';

// Styles
import './Slide1Mobile.scss';
import ArrowIcon from '../../../../../atoms/ArrowIcon';

const Slide1Mobile = () => (
    <div className="r4z-1-slide-content-mobile">
        <div className="r4z-1-slide-content">
            <div className="r4z-1-slide-content__text-wrapper">
                <h2 className="r4z-1-slide-content__title">
                    Community for
                    {' '}
                    <span>
                        sustainability
                        <br />
                        seekers and providers
                    </span>
                </h2>
                <p className="r4z-1-slide-content__text">
                    Designed to apply sustainability to all your business layers
                    {' '}
                    <br />
                    by community based smart solutions.
                </p>
            </div>
        </div>
        <div className="r4z-1-slide-content-mobile__scroll-down-button-wrapper">
            <ArrowIcon
                type="down"
                color="white"
                text="Scroll for More"
                animation
            />
        </div>
    </div>
);

export default Slide1Mobile;
