import HippoIconBlack from '../../../../../assets/icon/hippo_icon_black.svg';
import LeafIconWhite from '../../../../../assets/icon/leaf_icon_white.svg';
import TurtleIconBlack from '../../../../../assets/icon/turtle_icon_black.svg';

export const images = {
    turtleBlack: TurtleIconBlack,
    leafWhite: LeafIconWhite,
    hippoBlack: HippoIconBlack

};

export const contentSections = [
    {
        title: 'Value',
        preTitle: 'To generate more',
        text: 'Sustainable community to succeed in implementing solutions to save companies resources, efforts and expenses.',
        icon: 'turtleBlack',
        color: 'black'
    },
    {
        pageTitle: 'Discover ENVIRONMENT',
        title: 'Impact',
        preTitle: 'To know your',
        text: 'Evaluate and measure your business performance in sustainability context.',
        icon: 'leafWhite',
        color: 'white',
        button: false
    },
    {
        title: 'Match',
        preTitle: 'To find perfect',
        text: 'We direct you to partners to apply for changes immediately to act at once.',
        icon: 'hippoBlack',
        color: 'black'
    }
];
