import React from 'react';

// Styles
import './ProductPreview.scss';

// Assets
import ProductImage from '../../../../../../assets/img/reaching-for-zero-calculator.png';

type Props = {
    isActive: boolean
}

const ProductPreview = (props: Props) => {
    const {
        isActive
    } = props;

    return (
        <div className={`r4z-product-preview-content__wrapper ${isActive ? 'active' : ''}`}>
            <div className="r4z-product-preview-content__title-wrapper">
                <h2 className="r4z-product-preview-content__pre-title-text">Check your IMPACT using our product</h2>
                <h2 className="r4z-product-preview-content__title-text">SUSTAINABILITY IMPACT CALCULATOR</h2>
            </div>
            <div className="r4z-product-preview-content__product-hero-image-wrapper">
                <div className="r4z-product-preview-content__product-hero-image-container">
                    <img
                        src={ProductImage}
                        alt="reaching for zero calculator preview"
                        className="r4z-product-preview-content__product-hero-image"
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductPreview;
