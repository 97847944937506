import React, { useState } from 'react';
import ReactPageScroller from 'react-page-scroller';

// Styles
import './Home.scss';

// HOC
import withMobile from '../../../lib/hoc/withMobile';

// UI Components
import SideSlideNavigator from '../../molecules/SideSlideNavigator/SideSlideNavigator';
import ContactUsModal from '../../organism/ContactUsModal';
import Header from '../../templates/Header';

// Content components
import { Slide1ContentDesktopView, Slide1ContentMobileView } from './Content/Slide1';
import { Slide2ContentDesktopView, Slide2ContentMobileView } from './Content/Slide2';
import { VisionSlideDesktopView, VisionSlideMobileView } from './Content/VisionSlide';
import { Slide4ContentDesktopView, Slide4ContentMobileView } from './Content/Slide4';
import { Slide5ContentDesktopView, Slide5ContentMobileView } from './Content/UnitedNationsAgenda';
import { ProductPreviewView, ProductPreviewMobileView } from './Content/ProductPreviewSlide';
import { Slide6ContentDesktopAndMobileView } from './Content/RequestDemo';
import SiteFooter from '../../templates/Footer';

// Types
type ReduxProps = {
    isMobile: boolean
}

type Props = ReduxProps & {
    children: React.ReactNode;
};

const headerColorThemes = [
    { logoColor: 'white', menuColor: 'white' },
    { logoColor: 'black', menuColor: 'white' },
    { logoColor: 'white', menuColor: 'white' },
    { logoColor: 'black', menuColor: 'black' },
    { logoColor: 'white', menuColor: 'white' },
    { logoColor: 'black', menuColor: 'black' },
    { logoColor: 'black', menuColor: 'black' }
];

const Home = (props: Props) => {
    const {
        isMobile
    } = props;
    const [currentPage, changePage] = useState(0);
    const [modalVisible, handleModal] = useState(false);
    const handleContactModalOpen = () => handleModal(true);
    const handleModalClose = () => handleModal(false);
    return (
        <>
            <ContactUsModal
                visible={modalVisible}
                onClose={handleModalClose}
            />
            <Header
                topMenuVisible={isMobile || currentPage === 0}
                headerColorTheme={isMobile ? { menuColor: 'black', logoColor: 'black' } : headerColorThemes[currentPage]}
                types={isMobile ? ['sticky', 'mobile'] : ['sticky']}
                headerBackgroundColor={isMobile ? 'white' : null}
                {...{ handleContactModalOpen }}
            />
            {isMobile ? (
                <>
                    <Slide1ContentMobileView />
                    <VisionSlideMobileView />
                    <ProductPreviewMobileView />
                    <Slide2ContentMobileView />
                    <Slide4ContentMobileView />
                    <Slide5ContentMobileView />
                    <Slide6ContentDesktopAndMobileView
                        isActive={currentPage === 5}
                        // handleContactModalOpen={handleContactModalOpen}
                    />
                    <div className="r4z-mobile-section__footer-wrapper">
                        <SiteFooter {...{ handleContactModalOpen }} />
                    </div>
                </>
            ) : (
                <>
                    <SideSlideNavigator
                        items={headerColorThemes.map((i) => i.menuColor)}
                        activeColor={headerColorThemes[currentPage].menuColor}
                        activeIndex={currentPage}
                        onClick={(index) => changePage(index)}
                    />
                    <ReactPageScroller
                        animationTimer={600}
                        animationTimerBuffer={700}
                        customPageNumber={currentPage}
                        renderAllPagesOnFirstRender
                        onBeforePageScroll={(nextPage) => changePage(nextPage)}
                    >
                        <div className="component component--overlay section-1 bg">
                            <Slide1ContentDesktopView
                                isActive={currentPage === 0}
                                // onScrollClick={() => changePage(currentPage + 1)}
                            />
                        </div>
                        <div className="component section-2 bg">
                            <VisionSlideDesktopView
                                isActive={currentPage === 1}
                            />
                        </div>
                        <div className="component section-3 bg">
                            <ProductPreviewView isActive={currentPage === 2} />
                        </div>
                        <div className="component bg">
                            <Slide2ContentDesktopView
                                isActive={currentPage === 3}
                                // onScrollClick={() => changePage(currentPage + 1)}
                            />
                        </div>
                        <div className="component section-4 bg">
                            <Slide4ContentDesktopView
                                isActive={currentPage === 4}
                            />
                        </div>
                        <div className="component section-5 bg">
                            <Slide5ContentDesktopView
                                isActive={currentPage === 5}
                                // onScrollClick={() => changePage(currentPage + 1)}
                            />
                        </div>
                        <div className="component section-6 bg">
                            <Slide6ContentDesktopAndMobileView
                                isActive={currentPage === 6}
                                // handleContactModalOpen={handleContactModalOpen}
                            />
                            <div className="r4z-home-page__footer-menu">
                                <SiteFooter {...{ handleContactModalOpen }} />
                            </div>
                        </div>
                    </ReactPageScroller>
                </>
            )}
        </>
    );
};

export default withMobile<{}>({})(Home);
