import React from 'react';

// Styles
import './TopMenu.scss';

// Components
import Button from '../../atoms/Button';

// HOC
import MenuIcon from '../../atoms/MenuIcon';

// Types
import { MenuItem } from '../../templates/Header/data';

type Props = {
    menuItems: MenuItem[]
    showMenuIcon: boolean
    variant?: string
    onButtonClick: () => void
    toggleSideMenu: (state: boolean) => void
    handleNavigate: (url: string | null, newTab: boolean) => void
}

const TopMenu = (props: Props) => {
    const {
        variant = 'white',
        onButtonClick,
        menuItems,
        showMenuIcon,
        toggleSideMenu,
        handleNavigate
    } = props;

    return (
        <>
            {showMenuIcon ? (
                <div className="r4z-top-menu__menu-icon-wrapper">
                    <MenuIcon
                        onClick={() => toggleSideMenu(true)}
                        color={variant}
                    />
                </div>
            ) : (
                <nav className={`r4z-top-menu r4z-top-menu--${variant}`}>
                    <div className={`r4z-top-menu__body ${!showMenuIcon ? '' : 'r4z-top-menu__body--hidden'}`}>
                        {menuItems.map(({
                            title, link, newTab, action, onClick
                        }) => (
                            <div
                                className="r4z-top-menu__link-wrapper"
                                key={title}
                            >
                                <a
                                    className={`r4z-top-menu__link ${window.location.pathname === link ? 'r4z-top-menu__link--active' : ''}`}
                                    onClick={() => (action === 'navigate' ? handleNavigate(link, newTab) : onClick())}
                                    onKeyDown={() => (action === 'navigate' ? handleNavigate(link, newTab) : onClick())}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {title}
                                </a>
                            </div>
                        ))}
                        <div className="r4z-top-menu__get-started-button-wrapper">
                            <Button
                                text="Request Demo"
                                variant={`${variant}-transparent`}
                                fontSize="13.33px"
                                onClick={onButtonClick}
                            />
                        </div>
                    </div>
                </nav>
            )}
        </>
    );
};

export default TopMenu;
