import React from 'react';

// Styles
import './SideSlideNavigator.scss';

// Types
type Props = {
    items: {}[]
    activeIndex: number
    activeColor: string
    onClick: (index) => void
}

const SideSlideNavigator = (props: Props) => {
    const {
        items,
        activeIndex,
        activeColor,
        onClick
    } = props;

    return (
        <div className="r4z-slide-navigator__wrapper">
            {items.map((color, index) => (
                <div
                    key={index}
                    className={`r4z-slide-navigator__dot r4z-slide-navigator__dot--${activeColor} ${activeIndex === index ? 'r4z-slide-navigator__dot--active active' : ''}`}
                    onClick={() => onClick(index)}
                    onKeyDown={() => onClick(index)}
                    role="button"
                    tabIndex={index}
                    aria-label={`Navigate to Slide ${index}`}
                />
            ))}
        </div>
    );
};

export default SideSlideNavigator;
