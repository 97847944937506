import React from 'react';

import './MenuIcon.scss';

type MenuIconProps = {
    onClick: () => void
    color: string
}
const MenuIcon = (props: MenuIconProps) => {
    const {
        onClick,
        color
    } = props;
    return (
        <div
            title="menu"
            className={`rfz-menu-button ${color}`}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
            role="button"
        >
            <span className={`rfz-menu-button__icon-wrap rfz-menu-button__icon-wrap--${color} rfz-menu-button--top-line`} />
            <span className={`rfz-menu-button__icon-wrap rfz-menu-button__icon-wrap--${color} rfz-menu-button--middle-line`} />
            <span className={`rfz-menu-button__icon-wrap rfz-menu-button__icon-wrap--${color} rfz-menu-button--bottom-line`} />
        </div>
    );
};

export default MenuIcon;
