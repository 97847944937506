import React from 'react';

// Styles
import './Slide4Content.scss';

// Data
import { contentSections } from '../data';

// UI Components
import ArrowIcon from '../../../../../atoms/ArrowIcon';

// Types
type Props = {
    isActive: boolean
    onScrollClick?: () => void
}
const Slide4Content = (props: Props) => {
    const {
        isActive,
        onScrollClick
    } = props;
    return (
        <div className={`r4z-4-slide-content__wrapper ${isActive ? 'active' : ''}`}>
            {contentSections.map(({
                title, text, icon, color, pageTitle
            }, index) => (
                <section
                    key={`${index}slide4`}
                    className={`
                    r4z-4-slide-content__section r4z-4-slide-content__section--${index + 1} 
                    r4z-4-slide-content__section--${color}`}
                >
                    {pageTitle && (
                        <div className="r4z-4-slide-content__page-title-wrapper">
                            <h2 className="r4z-4-slide-content__page-title">{pageTitle}</h2>
                        </div>
                    )}
                    <div className="r4z-4-slide-content-section__wrapper">
                        <div className="r4z-4-slide-content-section__icon">
                            <img src={icon} alt="Turtle Icon" />
                        </div>
                        <div className="r4z-4-slide-content-section__title-wrapper">
                            <h2 className="r4z-4-slide-content-section__title">{title}</h2>
                        </div>
                        <div className="r4z-4-slide-content-section__text-wrapper">
                            <p className="r4z-4-slide-content-section__text">
                                {text}
                            </p>
                        </div>
                    </div>
                </section>
            ))}
            {onScrollClick && (
                <div
                    className="r4z-slide-content__scroll-down-button-wrapper"
                    onClick={onScrollClick}
                    onKeyDown={onScrollClick}
                    role="button"
                    tabIndex={0}
                >
                    <ArrowIcon
                        type="down"
                        color="lightBlack"
                        animation
                    />
                </div>
            )}
        </div>
    );
};

export default Slide4Content;
