import React from 'react';

// Styles
import './VisionSlide.scss';

// UI Components
import MobileSectionTitle from '../../../../../atoms/MobileSectionTitle';

// Images
import heroImage from '../../../../../../assets/img/r4z-vision-target-banner.png';

// Constants
import { colors } from '../../../../../../lib';

// Data
import { contentSections } from '../data';

const VisionSlideMobile = () => (
    <div className="r4z-3-slide-content-mobile">
        <MobileSectionTitle titleText="Vision" titleColor={colors.$darkGreen} />
        <div className="r4z-3-slide-content-mobile__image-wrapper">
            <img src={heroImage} alt="" className="r4z-3-slide-content-mobile__image" />
        </div>
        {contentSections.map(({ sectionTitle, textTitle, text }, index) => (
            <section className="r4z-3-slide-content-mobile-content__section-wrapper" key={`Slide3ContentMobile${index}`}>
                <div className="r4z-3-slide-content-mobile-content__section-title-wrapper">
                    <h2 className="r4z-3-slide-content-mobile-content__section-title">
                        {sectionTitle}
                    </h2>
                </div>
                <div className="r4z-3-slide-content-mobile-content__section-text-wrapper">
                    <h2 className="r4z-3-slide-content-mobile-content__section-text-title">
                        {textTitle}
                    </h2>
                    <p className="r4z-3-slide-content-mobile-content__section-text">
                        {text}
                    </p>
                </div>
            </section>
        ))}
    </div>
);

export default VisionSlideMobile;
