import React from 'react';

// Styles
import './ContactUsModal.scss';

// UI Components
import Modal from '../../molecules/Modal';

// Images
import LinkedInImg from '../../../assets/img/linkedin2.png';

// Types
type Props = {
    visible: boolean
    onClose: () => void
}

const ContactUsModal = (props: Props) => {
    const {
        visible,
        onClose
    } = props;

    return (
        <Modal
            title="Contact Us"
            {...{ visible, onClose }}
        >
            <section className="r4z-contact-modal__container">
                <p className="r4z-contact-modal__text">
                    Just click the link below to get in touch.
                </p>
                <a href="https://www.linkedin.com/company/reaching-for-zero/" target="_blank" rel="noreferrer">
                    <img
                        src={LinkedInImg}
                        alt="LinkedIn"
                        className="r4z-contact-modal__image"
                    />
                </a>
            </section>
        </Modal>
    );
};

export default ContactUsModal;
