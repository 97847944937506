import React from 'react';

// Styles
import './Link.scss';

type Props = {
    href: string
    onClick?: () => void
    className?: string
    children: any
    role?: string
    openTab?: boolean
}

const navigate = (url) => {
    window.location.href = url;
};

const Link = (props: Props): JSX.Element => {
    const {
        children,
        href,
        openTab = false,
        onClick = null,
        className = '',
        role = 'link'
    } = props;
    return (
        <a
            href={href}
            onClick={onClick}
            className={`r4z-link ${className}`}
            role={role}
            target={openTab ? '_blank' : null}
            rel="noreferrer"
            onKeyDown={() => navigate(href)}
        >
            {children}
        </a>
    );
};

export default Link;
