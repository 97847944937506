import React from 'react';

// Styles
import './ProductPreviewMobile.scss';

// Assets
import ProductImage from '../../../../../../assets/img/reaching-for-zero-calculator-mobile.png';

const ProductPreviewMobile = () => (
    <div className="r4z-product-preview-content-mobile__wrapper">
        <div className="r4z-product-preview-content-mobile__title-wrapper">
            <h2 className="r4z-product-preview-content-mobile__pre-title-text">Check your IMPACT using the</h2>
            <h2 className="r4z-product-preview-content-mobile__title-text">
                SUSTAINABLE
                <br />
                IMPACT
                <br />
                {' '}
                CALCULATOR!
            </h2>
        </div>
        <div className="r4z-product-preview-content-mobile__product-hero-image-wrapper">
            <img
                src={ProductImage}
                alt="reaching for zero calculator preview"
                className="r4z-product-preview-content-mobile__product-hero-image"
            />
        </div>
    </div>
);

export default ProductPreviewMobile;
