import React, { useState } from 'react';

// Styles
import './login.scss';

// Assets
import LogoBlack from '../../../assets/img/Logo100x100_black.svg';

// UI Components
import Header from '../../templates/Header';
import ContactUsModal from '../../organism/ContactUsModal';
import LoginForm from '../../organism/LoginForm';

const Login = () => {
    const [modalVisible, handleModal] = useState(false);
    const handleContactModalOpen = () => handleModal(true);
    const handleModalClose = () => handleModal(false);
    return (
        <>
            <Header
                topMenuVisible
                headerColorTheme={{ menuColor: 'black', logoColor: 'black' }}
                handleContactModalOpen={handleContactModalOpen}
            />
            <ContactUsModal
                visible={modalVisible}
                onClose={handleModalClose}
            />
            <div className="r4z-login-page__form-wrapper">
                <img src={LogoBlack} alt="logo" className="r4z-login-page__form-logo" />
                <h2 className="r4z-login-page__form-title">Sign in to continue</h2>

                <LoginForm />
            </div>
        </>
    );
};

export default Login;
