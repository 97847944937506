import * as React from 'react';
import * as hoistNonReactStatics from 'hoist-non-react-statics';

// Utils
import { connect } from 'react-redux';
import { getDisplayName } from './utils';

export interface WithMobileProps {
    isMobile: boolean
}

// eslint-disable-next-line no-unused-vars
type WrapperFunction<T> = (WrappedComponent: React.ComponentType<T>) => React.ComponentType<T>

/**
 * Provides the following props from Redux:
 * isMobile
 * @param connectOptions - options object passed to react-redux connect()
 */
// eslint-disable-next-line max-len
const withMobile = <T extends {}>({ connectOptions = {} } = {}): WrapperFunction<T> => (WrappedComponent) => {
    const connectOptionsWithDefaults = {
        pure: true,
        forwardRef: false,
        ...connectOptions
    };
    /* WrappedComponent.propTypes = {
        ...WrappedComponent.propTypes,
        isMobile: PropTypes.bool.isRequired,
    }; */

    const WrappedComponentConnected = connect<WithMobileProps, {}, T, {}, WithMobileProps>(
        (state) => ({
            // mapStateToProps
            // ref: ownProps.forwardedRef,
            // forwardedRef: ownProps.forwardedRef,
            isMobile: state.isMobile
        }),
        null,
        null,
        connectOptionsWithDefaults
        // @ts-ignore: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31363
    )(WrappedComponent);
    // @ts-ignore
    // explanation at https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
    hoistNonReactStatics(WrappedComponentConnected, WrappedComponent);

    WrappedComponentConnected.displayName = `withMobile(${getDisplayName(WrappedComponent)})`;

    return WrappedComponentConnected;
};

export default withMobile;
