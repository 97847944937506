import React from 'react';

// UI Components
import FooterMenu from '../../organism/FooterMenu/FooterMenu';

// Constants
import { menuItems } from '../../../lib';

type Props = {
  handleContactModalOpen: () => void
}
const SiteFooter = (props: Props) => {
    const {
        handleContactModalOpen
    } = props;

    return (
        <>
            <FooterMenu
                {...{ menuItems }}
                onContactClick={handleContactModalOpen}
            />
        </>
    );
};

export default SiteFooter;
