import { combineReducers } from 'redux';

import {
    CHANGE_IS_MOBILE
} from '../actions';

function isMobile(state = false, action) {
    switch (action.type) {
    case CHANGE_IS_MOBILE:
        return action.isMobile;
    default:
        return state;
    }
}

export default combineReducers({
    isMobile
});
