import React from 'react';

// Styles
import './Slide5Content.scss';

// Images
import GrowingTreesIcon from '../../../../../../assets/img/trees_transparent-05.png';
// Components
import Button from '../../../../../atoms/Button';
import ArrowIcon from '../../../../../atoms/ArrowIcon';

// Types
type Props = {
    isActive: boolean
    onScrollClick?: () => void
}

const Slide5Content = (props: Props) => {
    const {
        isActive,
        onScrollClick
    } = props;
    return (
        <div className={`r4z-5-slide-content__wrapper ${isActive ? 'active' : ''}`}>
            <div className="r4z-5-slide-content__header">
                <img
                    src={GrowingTreesIcon}
                    alt=""
                    className={`${isActive ? 'r4z-5-slide-content__header-image--animation' : ''}`}
                />
            </div>
            <div
                className={
                    `r4z-5-slide-content__text-wrapper ${isActive
                        ? 'r4z-5-slide-content__text-wrapper--animation'
                        : ''}`
                }
            >
                <p className="r4z-5-slide-content__text">
                    Business that seeks to achieve United Nations 2030 Agenda for Sustainable
                    Development and implement Sustainable Development Goals knows that it requires
                    all hands-on deck. It is a complex of different sectors and actors working
                    together in an integrated manner by pooling financial resources, knowledge and
                    expertise.
                </p>
            </div>
            <div className="r4z-5-slide-content__button-wrapper">
                <Button
                    text="More Information"
                    styleClass="r4z-5-slide-content__button"
                    fontSize="1.8em"
                    onClick={() => {
                        window.open('https://sdgs.un.org/goals', '_blank');
                    }}
                />
            </div>
            {onScrollClick && (
                <div
                    className="r4z-slide-content__scroll-down-button-wrapper"
                    onClick={onScrollClick}
                    role="button"
                    onKeyDown={onScrollClick}
                    tabIndex={0}
                >
                    <ArrowIcon
                        type="down"
                        color="lightBlack"
                        animation
                    />
                </div>
            )}
        </div>
    );
};

export default Slide5Content;
