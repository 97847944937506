import React from 'react';

// Styles
import './Slide5ContentMobile.scss';

// UI Components
import Button from '../../../../../atoms/Button';

// Images
import GrowingTreesIcon from '../../../../../../assets/img/trees_transparent-05.png';

const Slide5ContentMobile = () => (
    <div className="r4z-5-slide-content-mobile">
        <div className="r4z-5-slide-content-mobile__image-wrapper">
            <img
                src={GrowingTreesIcon}
                alt="Growing tree"
                className="r4z-5-slide-content-mobile__image"
            />
        </div>
        <div className="r4z-5-slide-content-mobile__text-wrapper">
            <p className="r4z-5-slide-content-mobile__text">
                Business that seeks to achieve United Nations 2030 Agenda for Sustainable
                Development and implement Sustainable Development Goals knows that it requires all
                hands-on deck. It is a complex of different sectors and actors working together in
                an integrated manner by pooling financial resources, knowledge and expertise.
            </p>
        </div>
        <div className="r4z-5-slide-content-mobile__button-wrapper">
            <Button
                text="More Information"
                styleClass="r4z-5-slide-content-mobile__button"
                fontSize="1em"
                onClick={() => {
                    window.open('https://sdgs.un.org/goals', '_blank');
                }}
            />
        </div>
    </div>
);

export default Slide5ContentMobile;
