import React from 'react';

// Styles
import './ActionSection.scss';

// UI Components
import Button from '../../atoms/Button';

// Types
type Props = {
    title?: string
    text?: string
    containerHeight?: string
    buttonText?: string
    buttonAction?: () => void
    buttonStyleClass?: string
    buttonFontSize?: string
}

const ActionSection = (props: Props) => {
    const {
        title,
        text,
        containerHeight,
        buttonText,
        buttonAction,
        buttonStyleClass,
        buttonFontSize
    } = props;
    return (
        <div className="r4z-action-section" style={{ height: containerHeight }}>
            {title && (
                <h2 className="r4z-action-section__title">{title}</h2>
            )}
            {text && (
                <p className="r4z-action-section__text">{text}</p>
            )}
            {buttonAction && (
                <div className="r4z-action-section__button-wrapper">
                    <Button
                        text={buttonText}
                        styleClass={buttonStyleClass}
                        fontSize={buttonFontSize}
                        onClick={buttonAction}
                    />
                </div>
            )}
        </div>
    );
};

export default ActionSection;
