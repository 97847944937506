import React from 'react';

// Styles
import './SideSlider.scss';

// Types
type SideMenuProps = {
    isOpen: boolean
    onCloseClick: () => void
    position?: 'left' | 'right'
    children?: React.ReactNode
}

const SideSlider = (props: SideMenuProps) => {
    const {
        isOpen,
        onCloseClick,
        position = 'right',
        children
    } = props;
    return (
        <div className="r4z-side-slider">
            <div
                className={`r4z-side-slider__overlay r4z-side-slider__overlay--${isOpen ? 'open' : 'closed'}`}
                onClick={onCloseClick}
                onKeyDown={onCloseClick}
                role="button"
                tabIndex={0}
                aria-label="close button"
            />
            <div className={`r4z-side-slider__wrapper r4z-side-slider__wrapper--${position} ${isOpen ? 'open' : 'closed'}`}>
                <div className="r4z-side-slider__content">
                    {children}
                    <div
                        className="r4z-side-slider__close-button"
                        onClick={onCloseClick}
                        onKeyPress={(event) => event.key === 'Enter' && onCloseClick()}
                        aria-label="close side menu"
                        role="button"
                        tabIndex={0}
                    >
                        &times;
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideSlider;
