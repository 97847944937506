/**
 ********* Action Types
 * */

export const CHANGE_IS_MOBILE = 'CHANGE_IS_MOBILE';

/**
 ********* Action Creators
 * */

export function changeIsMobile(isMobile) {
    return {
        type: CHANGE_IS_MOBILE,
        isMobile
    };
}
