import React, { useState } from 'react';

// Styles
import './About.scss';

// UI Components
import Header from '../../templates/Header';
import SiteFooter from '../../templates/Footer';
import ContactUsModal from '../../organism/ContactUsModal';
import ContentSection from '../../organism/ContentSection';
import ContentSectionHeroBanner from '../../organism/ContentSectionHeroBanner';
import ActionSection from '../../organism/ActionSection';

// Content
import { content } from './data';

const About = () => {
    const [modalVisible, handleModal] = useState(false);
    const handleContactModalOpen = () => handleModal(true);
    const handleModalClose = () => handleModal(false);
    return (
        <div className="r4z-about-page">
            <div className="r4z-about-page__header-wrapper">
                <Header
                    topMenuVisible
                    headerColorTheme={{ menuColor: 'white', logoColor: 'white' }}
                    handleContactModalOpen={handleContactModalOpen}
                    types={['scroll']}
                />
                <ContactUsModal
                    visible={modalVisible}
                    onClose={handleModalClose}
                />
                {/* <div className="r4z-about-page__title-wrapper"> */}
                {/*    <h2 className="r4z-about-page__title">About Us</h2> */}
                {/* </div> */}
            </div>
            {content.map((data, index) => {
                switch (data.component) {
                case 'content-section': {
                    const {
                        mainTitle,
                        mainTitleImg,
                        icon,
                        iconAltText,
                        secondaryTitle,
                        textParagraphs,
                        variant
                    } = data;
                    return (
                        <ContentSection
                            key={`${index}-about-us-section`}
                            {...{
                                key: `content-section-${index}`,
                                mainTitle,
                                mainTitleImg,
                                icon,
                                iconAltText,
                                secondaryTitle,
                                textParagraphs,
                                variant
                            }
                            }
                        />
                    );
                }

                case 'content-section-hero-banner': {
                    const {
                        title,
                        text,
                        image,
                        buttonText,
                        buttonLink,
                        buttonLinkOpenNewTab
                    } = data;
                    return (
                        <ContentSectionHeroBanner
                            key={`${index}-about-us-section`}
                            {...{
                                key: `content-section-${index}`,
                                title,
                                text,
                                image,
                                buttonText,
                                buttonLink,
                                buttonLinkOpenNewTab
                            }}
                        />
                    );
                }
                case 'content-section-contact': {
                    return (
                        <ActionSection
                            key={`${index}-about-us-section`}
                            containerHeight="250px"
                            title="Contact us for more. No time to wait."
                            buttonText="Contact Us"
                            buttonFontSize="1.2em"
                            buttonStyleClass="r4z-about-page__contact-us-button"
                            buttonAction={handleContactModalOpen}
                        />
                    );
                }
                default:
                    return null;
                }
            })}
            <div className="r4z-about-page__footer-menu-wrapper">
                <SiteFooter {...{ handleContactModalOpen }} />
            </div>
        </div>
    );
};

export default About;
