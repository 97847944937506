import React, { useState } from 'react';

// Components
import Header from '../../templates/Header';
import ContactUsModal from '../../organism/ContactUsModal';

const Blog = () => {
    const [modalVisible, handleModal] = useState(false);
    const handleContactModalOpen = () => handleModal(true);
    const handleModalClose = () => handleModal(false);
    return (
        <div>
            <Header
                topMenuVisible
                headerColorTheme={{ menuColor: 'white', logoColor: 'white' }}
                handleContactModalOpen={handleContactModalOpen}
            />
            <ContactUsModal
                visible={modalVisible}
                onClose={handleModalClose}
            />
        </div>
    );
};

export default Blog;
