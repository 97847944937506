import React from 'react';

// Styles
import './FooterMenu.scss';

// Components
import Link from '../../atoms/Link';

type Props = {
    menuItems: any[]
    onContactClick?: () => void
}

const handleItemType = (props, callback?): JSX.Element => {
    const {
        type,
        text,
        url
    } = props;
    switch (type) {
    case 'text':
        return <p>{text}</p>;
    case 'link':
        return <Link href={url}>{text}</Link>;
    case 'contactModal':
        return <p className="r4z-footer-menu__clickable-text" onClick={callback} onKeyDown={callback}>{text}</p>;
    default:
        return null;
    }
};

const FooterMenu = (props: Props) => {
    const {
        menuItems,
        onContactClick
    } = props;
    return (
        <div className="r4z-footer-menu">
            <div className="r4z-footer-menu__links-wrapper">
                {menuItems.map((item, index) => (
                    <div
                        key={`${index}footer`}
                        className={`r4z-footer-menu__item ${item.variant ? `r4z-footer-menu__item--${item.variant}` : ''} `}
                    >
                        {item.type === 'contactModal' ? handleItemType(item, onContactClick) : handleItemType(item)}
                    </div>
                ))}
            </div>
            <div className="r4z-footer-menu__legal-text">
                ©
                {new Date().getFullYear()}
                {' - '}
                Reaching4Zero. All rights reserved.
            </div>
        </div>
    );
};

export default FooterMenu;
