const Message = (function constructor() {
    const component = {
        body: document.getElementsByTagName('body')[0],
        container: document.createElement('div'),
        /**
     * Mount Message container and styles to DOM
     */
        mount() {
            // Add container Class
            this.container.setAttribute('class', 'flash-message');

            // Append Message Container To Body
            this.body.appendChild(this.container);

            // Set Flag to avoid repetition
            this.mounted = true;
        },
        /**
     * Create Message Content container
     * @param type
     * @param msg
     */
        create(type, msg) {
            // Check if component is mounted
            if (!this.mounted) {
                this.mount();
            }

            // Icon
            const icon = this.createIcon(type);

            // Text
            const text = document.createElement('span');
            text.textContent = msg;

            // Content element
            const content = document.createElement('div');
            content.setAttribute('class', 'flash-message-inner-content');
            if (icon) {
                content.appendChild(icon);
            }
            content.appendChild(text);

            // Inner element
            const inner = document.createElement('div');
            inner.setAttribute('class', 'flash-message-inner');
            inner.classList.add('class', 'appear-animation');
            inner.appendChild(content);

            // Message Container
            this.container.appendChild(inner);

            // Set Time out to remove Message
            setTimeout(this.remove.bind(this, inner), 3500);
        },
        /**
     * Destroy Message Content container
     * @param el
     * @returns {Node}
     */
        destroy(el) {
            return this.container.removeChild(el);
        },
        /**
     * Set Remove Animation
     * @param el
     * @returns {number}
     */
        remove(el) {
            el.classList.add('remove-animation');
            return setTimeout(this.destroy.bind(this, el), 200);
        },
        /**
     * Create Message Icon by message type
     * @param type
     * @returns {HTMLImageElement}
     */
        createIcon(type) {
            const icon = document.createElement('img');
            icon.setAttribute('class', 'icon');
            switch (type) {
            case 'error':
                icon.setAttribute('src', '/images/cross.svg');
                break;
            case 'success':
                icon.setAttribute('src', '/images/success.svg');
                break;
            case 'info':
                icon.setAttribute('src', '/images/default.svg');
                break;
            default:
                return null;
            }
            return icon;
        }
    };
    /**
   * Return Message Object and lock properties
   */
    return Object.freeze({
        success(message) {
            return component.create('success', message);
        },
        error(message) {
            return component.create('error', message);
        },
        info(message) {
            return component.create('info', message);
        },
        default(message) {
            return component.create('default', message);
        }
    });
}());

export default Message;
