const validate = {
    notEmpty: (value) => {
        if (value === undefined || value === '' || value === null) {
            return true;
        }
        return false;
    }
};

export function validateField(validators = [], value) {
    const error = validators.find(({ name }) => validate[name] && validate[name](value));
    return error ? error.errorMessage : null;
}
